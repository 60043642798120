import React from 'react';
import { BsLinkedin, BsGithub, BsInstagram} from 'react-icons/bs';
// import { FaFacebookF } from 'react-icons/fa';

const SocialMedia = () => (
  <div className="app__social">
    <div>
    <a href= {'https://www.linkedin.com/in/mukhtasar93' } target = "_blank" rel="noreferrer">  
      <BsLinkedin />
    </a>
    </div>
    <div>
      <a href= {'https://www.github.com/mukhtasar93' } target = "_blank" rel="noreferrer"> 
      <BsGithub />
      </a>
    </div>
    <div>
      <a href= {'https://www.instagram.com/mukhtasar93' } target = "_blank" rel="noreferrer"> 
      <BsInstagram />
      </a>
    </div>
  </div>
);

export default SocialMedia;
