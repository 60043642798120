import { createClient } from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

export const client = createClient({
	projectId: "xmq6s550",
	dataset: "production",
	apiVersion: "2023-06-09",
	useCdn: true,
	token: "ska84a3YI3lFfae5Q1NA0Fwy9K6aHi0XFT35fMuYQSL0FfD6CnzWC3fkPWd20fGEh0ObCq5sXeKjohPeMlC5EFxwsQK2R9hCvZUAsOSr2Ll031Hufa29ngvQnHwQIJngLzughJ5rwyAT3dd7jk0dVwfkLEnaHdqfh79NFcnTsQQA3SNnS7UB",
	ignoreBrowserTokenWarning: true,
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);
